export type IssuerForwardDataType = {
  issueSlug: string | null
  issueName: string | null
  companyName: string | null
}

export function useContactIssuerForward() {
  const isContactIssuerForwardDialogOpened = useState('isContactIssuerForwardDialogOpened', () => false)

  const data = useState<IssuerForwardDataType>('forwardIssuerData', () => ({ issueSlug: null, issueName: null, companyName: null }))

  function openContactIssuerForwardDialog(issuerForwardData: IssuerForwardDataType) {
    data.value = { ...issuerForwardData }
    isContactIssuerForwardDialogOpened.value = true
  }

  return {
    isContactIssuerForwardDialogOpened,
    issuerData: data,

    openContactIssuerForwardDialog,
  }
}
